html {
    scroll-behavior: smooth;
}

h4,
h5,
h6,
p,
li {
    font-weight: 700;
}

li {
    color: #fff;
}

body {
    /* background-image: url(../images/BG.jpg);
    background-size: cover; */
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-color: #000;
}

textarea,
select,
input[type="option"],
input[type="text"],
input[type="button"] {
    -webkit-appearance: none !important;
    border-radius: 0;
}

.form-control-dark {
    background-color: #000 !important;
    color: #fff !important;
}

.bg-black {
    background-color: #000 !important;
}

input[type="hidden"] {
    display: none !important;
}

input[type="checkbox"] {
    height: 18px;
    width: 18px;
    font-size: 15px;
    margin: auto 0;
}

select {
    text-align: center;
    text-align-last: center;
}

select#reptile-pick,
select#status-choice,
select#organizeStyle,
select#mark-select,
#weight-ideal-dialog select,
select#gene_type {
    -webkit-appearance: none !important;
}

hr {
    border-color: #2bc3c3;
}

.text-blue {
    color: #2bc3c3 !important;
}

.app {
    height: 100vh;
}

.app input.home-input {
    border-radius: 5px;
    position: relative;
    background: #000;
    color: #2bc3c3;
    text-align: center;
    height: 45px;
    margin-bottom: 10px;
}

.select-container::after {
    display: inline-block;
    width: 0;
    height: 0;
    position: relative;
    left: -30px;
    float: right;
    top: -35px;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid #fff;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

#gene_type_container.select-container::after {
    top: -20px;
}

.btn-outline-light-blue {
    color: #5abdc6;
    border-color: #5abdc6;
}

.modal {
    display: flex;
    flex-direction: column;
}

#tutorial-container {
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, .8);
    width: 100%;
    z-index: 15;
}

#tutorial-popup {
    position: absolute;
    top: 50%;
    color: white;
    width: 80%;
    left: 10%;
    background: #000;
    border-radius: 10px;
    border: 1px solid;
    text-align: center;
    overflow: hidden;
}

#tutorial-header {
    background: #0f363a;
    font-size: 20px;
    padding: 17px;
}

#tutorial-text {
    padding: 10px;
    text-align: center;
    line-height: 2;
    font-size: 11px;

}

#tutorial-popup .close-btn {
    font-size: 15px;
}

.spotlight {
    z-index: 20 !important;
    color: #fff !important;
    position: relative;
    -webkit-transform: translate3d(0, 0, 0) !important;
}

#cloned-elements {
    position: absolute;
}

#cloned-elements * {
    /* position: fixed; */
}

.unsetPosition {
    position: initial !important;
}

.overflow-hide {
    overflow: hidden !important;
}

#tutorial-container.tutorial-super {
    z-index: 21;
}

#chapter-container {
    display: none;
    left: min(-67%, -235px);
}

#chapter-container.active {
    display: block;
    left: min(-67%, -235px);
}

#chapter-container #open-tutorial-btn,
body #chapter-container .close-btn {
    padding: 10px;
    width: min-content;
    float: right;
    background: #0f363a;
    box-shadow: none;

}

#chapter-container #open-tutorial-btn {
    left: -30px;
}

.details-tutorial {
    margin-right: 10px;
    color: #fff;
}

#reptile-pick-container::after {
    top: 25px;
    left: -26%;
}

#organize-select-container::after,
#mark-select-container::after {
    position: fixed;
    top: initial;
    bottom: 35px;
}

#mark-select-container::after {
    z-index: 10;
    right: 120px;
    left: initial;
    float: right;
    display: none;
}

#organize-select-container::after {
    left: 37%;
    z-index: 7;
}

#weight-settings-select-container::after {
    top: -40px;
}

#age-settings-select-container::after {
    left: -54%;
    top: 27px;
}

[class*="column"]+[class*="column"]:last-child {
    float: left;
}

.no-pad {
    padding: 0;
}

.block {
    padding: 10px 0;
}

h1 {
    color: #2bc3c3;
    padding: 20px 0;
}

.animated-slow {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

#intro {
    background-color: #000;
    background-image: none;
    height: 100vh;
    width: 100vw;
    position: absolute;
}

#animation-intro {
    background-size: contain;
    z-index: 1000;
    position: absolute;
    background-repeat: no-repeat;
    background-color: #505050;
    background-position: center;
}

#animation-intro,
#presents,
#end-of-intro {
    height: 100vh !important;
    width: 100vw;
    z-index: 1000;
    position: absolute;
}

#presents {
    z-index: 1001
}

@media(min-width: 767px) {
    #animation-intro {
        background-size: contain;
    }
}

@media only screen and (min-width: 768px) {
    .small-block-grid-2>li:nth-of-type(2n+1) {
        clear: both;
    }
}

@media(max-width: 767px) {
    .small-block-grid-2>li {
        width: 100%;
    }
}

@keyframes wiggle {
    0% {
        transform: rotate(.5deg);
    }

    25% {
        transform: rotate(-.5deg);
    }

    50% {
        transform: rotate(.5deg);
    }

    75% {
        transform: rotate(-.5deg);
    }

    100% {
        transform: rotate(.5deg);
    }
}

@keyframes fullLeftSlide0Anim {
    100% {
        left: 0%
    }
}

@keyframes fullLeftSlide1Anim {
    100% {
        left: -100%
    }
}

@keyframes fullLeftSlide2Anim {
    100% {
        left: -200%
    }
}

@keyframes fullLeftSlide3Anim {
    100% {
        left: -300%
    }
}

@keyframes fullLeftSlide4Anim {
    100% {
        left: -400%
    }
}

@keyframes fullLeftSlide5Anim {
    100% {
        left: -500%
    }
}

@keyframes fullLeftSlideResetAnim {
    100% {
        left: 0%
    }
}

@keyframes fullRightSlide0Anim {
    100% {
        left: 0%
    }
}

@keyframes fullRightSlide1Anim {
    100% {
        left: -100%
    }
}

@keyframes fullRightSlide2Anim {
    100% {
        left: -200%
    }
}

@keyframes fullRightSlide3Anim {
    100% {
        left: -300%
    }
}

@keyframes fullRightSlide4Anim {
    100% {
        left: -400%
    }
}

@keyframes fullRightSlideResetAnim {
    100% {
        left: 0%
    }
}

.fullRightSlide1,
.fullRightSlide2,
.fullRightSlide3,
.fullRightSlide4,
.fullRightSlide5,
.fullLeftSlide0,
.fullLeftSlide1,
.fullLeftSlide2,
.fullLeftSlide3,
.fullLeftSlide4,
.fullLeftSlide5 {
    animation-duration: .3s;
}

.fullRightSlide1 {
    animation: fullRightSlide0Anim;
}

.fullRightSlide2 {
    animation-name: fullRightSlide1Anim;
}

.fullRightSlide3 {
    animation-name: fullRightSlide2Anim;
}

.fullRightSlide4 {
    animation-name: fullRightSlide3Anim;
}

.fullRightSlide5 {
    animation-name: fullRightSlide4Anim;
}

.fullLeftSlide0 {
    animation-name: fullLeftSlide0Anim;
}

.fullLeftSlide1 {
    animation-name: fullLeftSlide1Anim;
}

.fullLeftSlide2 {
    animation-name: fullLeftSlide2Anim;
}

.fullLeftSlide3 {
    animation-name: fullLeftSlide3Anim;
}

.fullLeftSlide4 {
    animation-name: fullLeftSlide4Anim;
}

.fullLeftSlide5 {
    animation-name: fullLeftSlide5Anim;
}

.currentSlide0 {
    left: 0;
}

.currentSlide1 {
    left: -100%;
}

.currentSlide2 {
    left: -200%;
}

.currentSlide3 {
    left: -300%;
}

.currentSlide4 {
    left: -400%;
}

.currentSlide5 {
    left: -500%;
}

#collection-dialog #sliding-container {
    left: -100%;
    width: 500%;
}

.wiggle {
    display: inline-block;
    animation: wiggle .5s infinite;
}

.button[disabled] {
    background: gray;
}

.title {
    color: #fff;
    padding: 20px 0;
    background-size: cover;
    margin-bottom: 25px;
}

h2,
h3,
h4,
h5,
h6 {
    color: #fff;
}

.modal h2 {
    color: #000;
}



#logo {
    margin-bottom: 30px;
    height: 150px;
    width: 100%;
    overflow: hidden;
}

#logo img {
    margin-bottom: 10px;
    height: 300px;
    padding-right: 1rem;
    margin-top: -60px;
}

.result-item {
    margin-bottom: 10px;
    color: #c6d6d2;
    background: #000;
    border-radius: 10px;
}


#results-modal .result-item .fraction h5 {
    font-size: 18px;
}

.glow {
    box-shadow: 0px 0px 9px #2bc3c3 !important;
}

.app .home-button,
#settings-container .button,
#chapter-container .button,
.morph-button {
    border: 0;
    color: #c6d6d2;
    background: #000000;
    box-shadow: 0px 0px 9px #2bc3c3;
    font-size: 14px;
    padding: 16px;
    text-align: center;
    width: 54%;
    border-radius: 21px !important;
}

#chapter-container .button {
    width: 100%;
}

.calcname {
    color: #000;
}

.keeper-morphname {
    font-size: 13px;
}

#morph-name {
    text-align: center;
    color: #fff;
    font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
    /* margin-bottom:0; */
    overflow: hidden;
}

.content {
    background: rgba(0, 0, 0, 0.6);
    margin: 15px;
}

.button {
    text-transform: capitalize;
    padding: 10px;
    color: #bcc6c5;
    font-weight: 400;
    font-family: calibri;
    box-shadow: none;
    background: #0f363a;
    border: 1px solid #bcc6c5;
    border-radius: 10px;
    text-shadow: 3px 5px 4px #000;
}

.switch-container {
    margin: 10px 0;
}

.picture-dots {
    position: relative;
    float: left;
    text-align: center;
    width: 100%;
    padding: 10px 0;
}

.picture-dot {
    margin: 0 5px;
    color: rgb(68, 68, 68);
}

.picture-dot.active {
    color: #4b9aa1;
}

.button:hover {
    background-color: #4c7977;
}

select.button:hover {
    background-color: #000;
}

.price {
    font-size: 20px;
}

.collection-acc {
    padding: 20px;
    background: #3caf95a6;
    color: #cbe4e2;
    box-shadow: 5px 4px 3px #031f1a;
    margin-top: 10px;
    border-radius: 10px;
}

.sold-acc {
    padding: 15px 5px;
    box-shadow: 0px 0px 10px;
    border-radius: 10px;
    margin-bottom: 15px;
}

#sold-dialog {
    z-index: 9;
}

#sold-dialog .modal-content {
    height: 60%;
}

#growth-chart {
    text-align: right;

}

.collection-acc.morph-button {
    width: 95%;
    left: 2.5%;
    margin: 9px 0;
    padding: 15px;
    border-radius: 10px;
    color: #c6d6d2;
    background: #000000;
    box-shadow: 0px 0px 9px #2bc3c3;
}
@media(min-width: 768px) {
    .collection-acc.morph-button {
        width: 47%;
        margin: 0 auto;
    }
}
.picker__button--today,
.picker__button--clear,
.picker__button--close {
    background: #000;
}

.collection-acc input,
#date-all {
    background: none;
    color: #fff;
    border: 0;
    text-align: right;
    font-size: 19px;
    padding-right: 0;

}

.collection-acc input {
    text-align: center;
}

#float-button-container {
    bottom: 5px;
    right: 10px;
}

#float-button-container .button {
    border-radius: 30px;
}

.morph-button,
.calculate,
.float-button.delete {
    padding: 16px;
    /* border: 1px solid #ebf7f7; */
    background-position-x: -41px;
    font-size: 16px;
    background-size: cover;
    color: #ebf7f7;
    text-shadow: 3px 5px 4px #000;
}

.splash-button {
    background-size: cover;
    width: 37%;
    color: #ccd0d0;
    background-position: initial;
    font-size: 16px;
    padding: 31px;
    float: left;
    width: 100%;

}

.more-info-container img {
    margin-top: 15px;
}

.calculate,
.float-button.delete {
    background-position: initial;
    color: #d8e2e2;
    border: 1px solid #9cbbbb;
}

#scroller,
#growth-table-tbody {
    border: 1px solid #fff;
}

.float-button.delete {
    background: #ff0000;
    padding: 8px;
    border: 0;
    text-shadow: initial;
}

#weight-settings {
    margin-top: -4px;
}

select,
.morph-button,
.combobox-container {
    padding: 5px 10px;
    border-radius: 10px;
    color: #c6d6d2;
    background: #000000;
}

.morph-button,
.combobox-container {
    box-shadow: 0px 0px 9px #2bc3c3;
}

#weight-ideal-dialog .ui-selectmenu-button,
#weight-ideal-dialog .ui-selectmenu-button-text {
    background: none;
    border: none;
    color: #000;
    width: 100%;
}

#weight-ideal-dialog .ui-selectmenu-button-text,
#weight-ideal-dialog .ui-selectmenu-button-icon {
    display: none;
}

#weight-ideal-dialog .ui-selectmenu-button {
    padding: 10px 0;
}

.combobox-container {
    margin: 0 0 1em 0;
    border: 1px solid #fff;
    float: left;
    width: 100%;
    height: min-content
}

.combobox-container input,
.combobox-container .dropdown-toggle,
.combobox-container input:focus {
    background: none;
    border: none;
    color: #c6d6d2;
}

.combobox-container .dropdown-toggle {
    padding: 5px;
    align-self: center;
}

.combobox-container input {
    margin: 0;
    font-size: 13px;
    width: initial;
}

#open-tutorial-btn {
    display: none;
}

.input-group {
    flex-wrap: nowrap;
}

.combobox-container .input-group {
    height: 100%;
    padding-top: auto;
    padding-bottom: auto;
}

.combobox-container .dropdown-toggle {
    min-width: 25px;
    padding: 5px 0;
    display: flex;
    flex-direction: row;
}

.dropdown-toggle::after {
    float: right;
    margin-top: 5px;
}

#collection-dialog input[type="date"],
#collection-dialog input[type="text"] {
    font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-style: normal;
    color: #ced8d5;
    ;
    text-rendering: optimizeLegibility;
    margin-top: 0.2em;
    margin-bottom: 0.5em;
    line-height: 1.2125em;
    width: 100%;
    font-size: 1rem;
    background: none;
    text-align: center;
    border: none !important;
    margin: 0;
    /* background: #0f363a; */
    padding: 10px 0;
}

div[role=status] {
    display: none;
}

input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
    margin: 0;
    padding: 0;
}

input[type=date]::-webkit-calendar-picker-indicator {
    /*   -webkit-appearance: none;
    display: none; */
    margin: 0;
    padding: 0;
    background: #000;
    color: #fff !important;
}

.float-button {
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 6;
    border: 1px solid;
    background: #0f363a;
    color: #fff;
    font-weight: 900;
    border-radius: 100px;
    padding: 10px;
}

.gender-radio {
    margin-bottom: 0px;
}

#mark-select {
    float: right;
    width: 50%;
    max-width: 200px;
    background: #000;
    box-shadow: 0px 0px 5px #2bc3c3;
}

#organizeStyle {
    width: 150px;
}

#organizeStyle:hover {
    background: #0f363a;

}

#alert {
    left: 5%;
    width: 90%;
    background: rgba(15, 54, 58, .8);
    border: 1px solid;
    color: #fff;
    bottom: 50px;
    z-index: 17;
}

.no-animation {
    -webkit-animation-fill-mode: initial !important;
    animation-fill-mode: initial !important;
}

.super-float {
    z-index: 14;
}

.facebook-btn {
    background: #3b5998;
}

.float-button-2 {
    right: 60px;
}

.float-button-3 {
    right: 100px;
}

.float-button-4 {
    right: 140px;
}

.float-button-5 {
    right: 180px;
}

.float-button-6 {
    right: 220px;
}

.float-button.calculate,
.float-button.delete {
    margin-bottom: 10px;
}

#open-tutorial-btn.header-btn {
    top: 20px;
    bottom: initial;
    right: 10px;
    background: #fff;
    color: #000;
    position: absolute;
}

#open-tutorial-btn.modal-btn {
    float: right;
    position: absolute;
    right: 50px;
    top: 10px;
    border: 0;
}

#mark-select {
    left: 10px;
    right: initial;
    width: min-content;
}

#login-modal {
    z-index: 16;
}
#login-modal .reptool-modal-body {
    height: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}
#login-modal .reptool-modal-body .login-container {
    margin-top:auto;
    margin-bottom:auto;
}
#login-modal .animated {
    padding: 0 !important;
}

#login-modal #logo img {
    width: 100%;
}

#search-bar {
    position: fixed;
    bottom: 12px;
    width: 60%;
    z-index: 10;
    right: 100px;
}

#loading,
.loading-gif {
    position: fixed;
    z-index: 15;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
}

.loading-gif img {
    margin: auto;
}

#loading.hidden {
    display: none;
}

#loading.blackout {
    background: #000;
}

#loading img {
    width: 70%;
    margin: auto 0;
}

#morph-picture {
    /*   max-height: 400px;
    max-width: 400px; */
    left: 25%;
}

.morph-pic {
    width: 100%;
}

#morph-description #description {
    min-height: 150px;
}

.collapse-box.collapsed .fa-chevron-down {
    transform: rotate(-90deg);
    transition: .3s;
}

#gene_type-button {
    background: none;
    padding: 0;
    border: 0;
    color: transparent;
    width: 100%;
}

#gene_type-button span {
    display: none;
}

#collection-dialog #morph-picture {
    max-height: 400px;
    max-width: 100%;
    left: 0%;
}

#genes-dialog,
#morph-dialog {
    z-index: 9;
}

#gallery-container {
    float: left;
    text-align: center;
    width: 100%;
}

#gallery-container .breeder-overlay {
    float: left;
    width: 100%;
}

#info_container {
    position: relative;
    overflow: scroll;
    float: left;
    width: 100%;
}

.hidden,
.ui-loader {
    display: none !important;
}

.ui-menu {
    z-index: 9000;
    background: #000 !important;
    color: #2bc3c3 !important;
}

.not-available-overlay {
    background: rgba(255, 255, 255, .3);
    position: absolute;
    z-index: 900;
    height: 60%;
    padding: 16px;
    color: #000;
    font-size: 18px;
    width: 86%;
    font-weight: 900;
    text-align: center;
}

.modal-content {
    padding: 20px;
    float: left;
    position: initial;
    width: 100%;
    background: #000;
}

.modal-title {
    background: #0f363a;
    text-align: center;
    font-size: 21px;
    color: #2bc3c3;
    margin-top: auto;
    margin-bottom: auto;
}

.fixed-title.modal .modal-title {
    position: fixed;
    z-index: 10;
    margin-top: 0;
    padding-top: 43px;
    width: 75%;
    font-size: 21px;
}

.fixed-title.modal .close-btn {
    position: fixed;
}

.fixed-title.modal .modal-content {
    margin-top: 70px;

}

#rep-info .modal-title {
    margin-top: 0;
}

.modal,
#results-modal {
    position: absolute;
    color: #63ccd6;
    /*  border: 1px solid #2bc3c3; */
    /* background: url(../images/btn-bg.jpg); */
    background: #000;
    /* height: 80%; */
    z-index: 8;
    width: 94%;
    left: 3%;
    top: 10%;
    background-size: cover;
}

#results-modal {
    background: #000;
}

#parents {
    margin-top: 15px;
}

#morph_info_modal,
#calculator-results,
.modal {
    /* position: fixed;
    max-height:90%;
    top:3%;*/
    position: fixed;
    height: 100%;
    /* top: 3%; */
    width: 100%;
    top: 0;
    left: 0;
    overflow-y: scroll;
    overflow-x: hidden;
}

rect.log-label {
    fill: #000;
}

rect text {
    fill: #63ccd6;
}

#ideal-breed,
.ideal-breed {
    fill: #63ccd6;
    opacity: .4;
    stroke: #63ccd6;
}

.hidden-anchor h6 {
    font-size: 21px;
    border-bottom: 1px solid;
    text-align: center;
}

.plot {
    stroke: #bcc6c5;
    fill: none;
    stroke-width: 3px;
}

text,
.legend.plot {
    fill: #bcc6c5;
}

#reptile-info-dialog {
    max-height: 95%;
    overflow: hidden;
}

#collection-dialog .gender-btn {
    font-size: 20px;
    top: 30px;
    right: 25px;
    margin-left: 1.5rem;
}

#collection-dialog .settings-btn {
    right: 10px;
}

.group-btn input {
    background: #000;
    border: 0;
    color: #fff;
    text-align: center;
    margin: 0 1.5rem;
}

.delete-group-btn {
    background: red;
    float: right;
    margin-right: 15px;
    color: #fff;
    height: fit-content;
    padding: 10px;
}

.group-btn {
    padding: .75rem 1.25rem;
}

#group-dialog #reptile-list .button {
    display: none;
}

#reptile-list {
    width: 40000px;
    float: left;
    overflow: scroll;
    display: inline-block;
}

#reptile-list-container {
    position: relative;
    float: left;
    max-width: 100%;
    margin-top: 100px;
    overflow: scroll;
}

.modal-footer {
    width: 100%;
    float: left;
}

#group-dialog .modal-content {
    height: 68vh;

}

#group-list {
    overflow: scroll;
}

#group-list-container {
    float: left;
    overflow: scroll;
    height: 100%;
    width: 100%;
}

.group-btn:hover {
    background-color: #000;
}

.group-btn.active {
    background-color: #4c7977;
}

#reptile-list-container .reptile-tile-container {
    width: 100px;
}

.collection-container .gender-btn {
    font-size: 70px;
    margin-bottom: 10px;
    width: 100%;
}

.gender-btn.genderless,
.gender-btn.none {
    background: #706e6e;
    color: #fff;
}

.gender-btn.female {
    background: #d5118e;
}

.gender-btn.male {
    background: #1128d5;
}

.gender-btn.small {
    font-size: 24px;
    text-align: center;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 3px 0;
    color: #fff;
}

.gender-input {
    padding: 0 2px;
}

#quantity-add-genes input[type=number],
#quantity-add-morph input[type=number] {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 30px;
    font-size: 17px;
}

.label-title {
    text-align: center;
    background: #0f363a;
    border-radius: 5px;
    margin-bottom: 10px;
}

.reptile-tile-container {
    margin-bottom: 10px;
    padding: 7px;
}

.reptile-tile {
    box-shadow: 0px 0px 7px #2bc3c3;
    padding: 10px;
    border-radius: 10px;
}

#list-grid-container {
    padding-bottom: 4rem;
    overflow: scroll;
}

.reptile-tile .button,
#collection-item .button {
    margin: 10px 0;
    margin-bottom: 2px;
}

.reptile-tile .gender-btn {
    margin-left: 10px;
}

#morph_info_modal .collection-item,
.modal-content {
    height: 85%;
    overflow: scroll;
    -webkit-overflow-scrolling: auto !important;
}

#morph_info_modal .collection-item {
    padding-top: 0px;
}

#info_container h3 {
    color: #bfccc9;
    font-size: 33px;
    font-family: calibri;
    background: #11433f;
    text-align: center;
    border-radius: 5px;
    text-shadow: 1px 3px 4px #000;

}

#info_container h5 {
    color: #5ec7d1;
}

#results-modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    /*  padding-right: 10px;
    padding-left: 10px; */
}

#results-modal.calc-results {
    top: 0;
    width: 100%;
    left: 0;
}

#weight-ideal-dialog h6 {
    background: #0f363a;
    text-align: center;
    margin: 0;
    padding: 2px 0;
}

#weight-ideal-dialog .small-6 {
    padding: 0;
}

#weight-ideal-dialog input {
    width: 100%;
    background: #000;
    height: 30px;
    text-align: center;
    color: #fff;
}

#weight-ideal-dialog .ui-selectmenu {
    width: 50%;
    float: left;
}

#weight-ideal-dialog .modal-content {
    padding: 10px;
}

#weight-ideal-dialog div#female-settings {
    padding-right: 2%;
    border-right: 1px solid #fff;
}

#weight-ideal-dialog div#male-settings {
    padding-left: 2%;
}

#weight-ideal-dialog .modal-title {
    font-size: 20px;
    padding: 0;
}

#growth-table-container {
    margin-bottom: 20px;
}

#collection-container li.ui-tabs-tab {
    width: fit-content;
    float: left;
    padding: 5px 10px;
}

.ui-tabs-panel {
    background-color: #000;
    border: 0;
}

#growth-table tbody {
    max-height: 400px;
    overflow: scroll;
    display: block;
}

#growth-table,
#growth-table-headers {
    width: 90%;
    left: 5%;
    position: relative;
    display: block;
}

#growth-table-headers {
    margin-bottom: 0;
}

#growth-table .tr * {
    text-align: center;
    background: #000;
    color: #fff;
}

#growth-table .tr {
    padding: 15px 0px;
}

/*#growth-table .tr div {
    width:41.66667%;
}
#growth-table .tr div.remove-td{
    width:16.66667%;
} */
.weight-arrow {
    font-size: 17px;
}

.weight-arrow .fa-arrow-up,
.weight-arrow .fa-arrow-down {
    margin-right: 5px;
}

#growth-table .tr div.remove-td * {
    background: #0f363a;
}

#growth-table .tr div .remove-row-btn {
    margin: 0;
    padding: 8px;
}

#growth-table-headers {
    border: 1px solid #fff;
    background: #0f363a;
}

#growth-table .tr .fa-arrow-up * {
    color: #4b9aa1;
}

#growth-table .tr .fa-arrow-down * {
    color: red;
}

table {
    width: 100%;
    border: 1px solid #fff;
}

th {
    background: #0f363a;
}

td,
th {
    padding: 1rem;

}

thead {
    border: 1px solid #2bc3c3;
}

#growth-table-headers .growth-header {
    background: #0f363a;
    text-align: center;
    padding: 8px;
    color: #fff;
}

#lineageTree {
    max-height: 71vh;
    overflow-y: scroll;
}

#lineageTree .reptile-tile span {
    width: 100%;
    text-overflow: hidden;
    font-size: 80%;
    height: 23px;
}

#lineageTree rect {
    fill: #0f363a;
    stroke: #fff;
}

#lineageTree rect.active {
    stroke: red;
}

#lineageTree line {
    stroke: #fff;
}

#lineageTree rect.cross {
    fill: #fff;
}

#container.active {
    /*  transform: scale(1.25); */
}

#morph_info_modal .sliding-item {
    float: left;
}

.sliding-item {
    width: 20%;
}

#morph_info_modal .sliding-item {
    width: 100%;
}

#growth-table input {
    width: 100%;
}

.breeder-overlay,
.calculator-credit {
    text-align: center;
    margin-top: 0;
    color: #2bc3c3 !important;
}

.calculator-credit,
.tile-photo-credit {
    font-size: 9px;
}

#results-modal hr {
    margin: 8px 0;
}

#results-modal .modal-content {
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
}

.result-item .small-6 {
    padding: 0;
}

#growth-chart {
    top: 20px;
}

@media(min-width:501px) {
    #growth-chart svg:not(:root) {
        float: left;
    }
}

@media(max-width:500px) {
    #growth-chart {
        /* transform: rotate(90deg); */
        top: 20px;
    }
}

#legend {
    top: 10px;
    position: relative;
}

#qr-code-error h2 {
    color: #fff;
}

.back-to-calc {
    margin-top: 27px;
    color: #ccdbde;
}

.light-box {
    position: fixed;
    width: 95%;
    top: 5%;
    left: 2.5%;
    padding: 15px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 2001;
}

.light-box img {
    width: 100%;
}

#grid-container {
    margin-bottom: 80px;
    width: 100%;
}

#inventory-carousel #grid-container {
    min-height: 63vh;
}

.modal h5,
.modal h3,
.modal h6 {
    color: #ced8d5;
}

#growth-chart-container {
    max-height: 60vh;
    overflow-y: scroll;
}

#growth-chart-container table {
    border-top: 0;
}

#growth-chart-container table tr {
    border-bottom: 1px solid #fff;
}

.react-datepicker-popper {
    z-index: 10;

}

#collection-controls {
    position: relative;
    bottom: 10px;
    left: 0;
    background-color: #000;
    padding-top: 20px;
    padding-bottom: 10px;
    z-index: 1;
}

#collection-dialog {
    max-height: 100vh;
    overflow: hidden;
}

#carousel-indicators {
    margin-top: 1rem;
}

#carousel-indicators .circle {
    margin: 0 .35rem;
    font-size: .75rem;
    color: #656565;
}

#carousel-indicators .circle.active {
    color: #2bc3c3;
}

.shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .6);
    z-index: 10;
    display: flex;
    justify-items: center;
}

#details-popup,
#calendar-popup {
    z-index: 10;
    background: #fff;
    padding: 5px;
    position: relative;
    color: #000;
}

#details-popup {
    top: 10%;
    margin: auto;
}

#calendar-popup {
    height: min-content;
    margin: 0 auto;
}


#tree-modal {
    z-index: 10;
}

#sliding-container {
    float: left;
    overflow: hidden;
    position: relative;
    height: 85%;
    display: flex;
}

#collection-dialog #sliding-container {
    display: block;
}

#morph_info_modal #sliding-container {
    height: initial;
}

.shift1 {
    left: -33.33333%;
}

.shift2 {
    left: -66.666666%;
}

#rep-info,
#growth-chart-container,
#growth-chart {
    /* width: 33.333%; */
}

.qr-code-container {
    text-align: center;
    top: 25%;
}

#collection-dialog h6,
#details-popup h6 {
    font-size: 10px;
    padding: 5px 0;
    background: #0f363a;
}

#rep-info h5 {
    font-size: 1rem;
}

#details-popup h6 {
    font-size: 15px;
    margin-bottom: 0;
}

#growth-table-tbody {
    overflow: scroll;
    padding-right: 10px;
}

#breeder-text-box {
    position: absolute;
    z-index: 10;
    text-align: center;
    color: #000;
    font-size: 20px;
}

.back-to-collection {
    /*   position: absolute;
    bottom: 15%;
    left: 10% */
}

#file-upload-button {
    background-color: #5abdc6;
    color: #fff;
}

#collection-dialog h6#photo-credit {
    background: #000;
    text-align: center;
    font-style: italic;
}

.keeper-dialog-img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 40vh;
}

@media(max-height: 630px) {
    .hideElementOnKeyboardShown {
        display: none;
    }
}

.date-info {
    background: #0f363a;
}

.diamond {
    position: relative;
    width: 30px;
    background: #0f363a;
    padding: 5px;
    border-bottom-color: #0f363a;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 1rem;
}

#collection-dialog {
    border: 0;
}

#collection-dialog h3 {
    margin: 0;
    /*  background: #0f363a; */
    padding: 10px 0;
}

.collection-field {
    /*  border:1px solid #ced8d5; */
}

.collection-item {
    /*  max-height:200px; */
    border: 1px solid green;
    padding: 10px;
    background: #000;
}

.light-box-arrows {
    position: absolute;
    z-index: 2002;
    top: 45%;
    font-size: 30px;
    color: #fff;
}

#next-arrow {
    right: 0;
}

#prev-arrow {
    left: 0;
}

#info_container .collection-item {
    border: 0;
    background: none;
}

.collection-item img {
    max-height: 100px;
}

#info_container .collection-item,
#info_container .collection-item img {
    max-height: 100%;
}

.genes-dialog-container {
    margin-bottom: 2.5rem;
    overflow: scroll-y;
}

#genes li {
    border: 1px solid;
    text-align: center;
    margin-bottom: 10px;
    list-style: none;
    border-radius: 5px;
    overflow: hidden;
    color: #2bc3c3;
}

#genes .morph-trait {
    padding: 5px;
}

#genes li h6 {
    color: #ced8d5;
    background: #11433f;
    margin-top: 0;
    margin-bottom: 0;
    padding: 5px;
    text-shadow: 1px 3px 4px #000;
}

#results-container {
    overflow: scroll;
}

.close-btn {
    color: #fff;
    /*  background: #ff0000;
    border-radius: 30px; */
    border: 1px solid #000;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9;
}

.shrink {
    height: 0;
    overflow: hidden;
}

.care-sheet-para {
    padding: 10px;
}

.care-sheet-para h2 {
    text-align: center;
    text-decoration: underline;
}

.care-sheet-para ul {
    padding-left: 2rem;
}

.care-sheet-para ul li {
    margin-top: .75rem;
}

.care-sheet-para p,
.content p {
    color: #fff;
    padding: 10px;
    text-transform: initial;
}

.collection-container {
    position: relative;
    float: left;
    padding: 0;
    padding-bottom: 5px;
}

#inventory-carousel .collection-container {
    padding: 0 auto;
}

#growth-chart svg:not(:root) {
    overflow: visible;
}

#growth-chart .ticks-container {
    overflow: hidden !important;
}

#rating-dialog {
    width: 50%;
    height: fit-content;
    top: 30%;
    left: 25%;
    float: left;
    padding: 7px;
    border: 1px solid;
    border-radius: 15px;
}

#rating-dialog .modal-title {
    font-size: 16px;
}

.rating-star {
    opacity: .2;
    text-align: center;
    font-size: 24px;
    padding: 0;
}

.rating-star.active {
    opacity: 1;
}

.remove-btn {

    /*  background: red; */
    color: #fff;
}

#use-btn {
    background: #cecece;
    color: #000;
}

#use-btn.checked {
    background: green;
    color: #fff;
}

#use-btn,
.remove-btn {
    position: absolute;
    z-index: 8;
    top: 4px;
    right: 10px;
    border-radius: 20px;
    border: 1px solid #000;
}

#picture_requirements_modal {
    z-index: 9;
}

#change-morph-dialog {
    z-index: 10;
}

#gene-edit-dialog,
picture_requirements_modal {
    z-index: 11;
}

#gene-edit-dialog .modal-content {
    height: initial;
    max-height: 60%;
}

#reptile-info-dialog img {
    padding: 20px 0;
}

#edit-reptile {
    margin-top: 20px;
}

#collectionName {
    font-size: 21px;
    border: 0;
    width: 100%;
}

.collection-item-female .collection-acc.selected,
.collection-item-male .collection-acc.selected {
    border: none;
}

.selected-item {
    border: 3px solid #00ff00;
}

.selected-delete-item {
    border: 3px solid #ff0000;
}

.danger-btn {
    background: red;
    color: #fff;
    border-color: red;
}

#settings-container,
#chapter-container {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    height: 100%;
    z-index: 8;
    padding: 15px;
    width: 60%;
    min-width: 220px;
    overflow: scroll;
}

#chapter-container.quick-reptile-switch {
    z-index: 8;
}

#chapter-container .button.active {
    background-color: #0f363a;
    color: #fff;
}

#open-switch {
    color: #fff;
    font-size: 22px;
    z-index: 4;
    padding: 20px 10px;
    background: black;
    opacity: .6;
    box-shadow: 0px 0px 10px #2bc3c3;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: none;
}

#open-switch-cover,
#open-switch {
    position: fixed;
    top: 50%;
    left: 0%;
}

#open-switch-cover.active {
    z-index: 9;
    background-color: #000;
    height: 63px;
    width: 7px;
    /* padding-left: 7px; */
    box-shadow: none;
    left: max(75%, 219px);
}

#open-switch.active {
    opacity: 1;
    left: max(60%, 220px);
}

a.back-arrow {
    color: #2bc3c3 !important;
    font-size: 1.5rem;
}

#settings-container .button {
    top: 40%;

}

#rating-dialog .stars-container {
    left: 8.33333%;
}

.d-block {
    display: block;
}

.w-100 {
    width: 100%;
}

#sliding-carousel {
    height: calc(100% - 150px);
}

.carousel-inner {
    height: 100%;
}

#morph-slider .carousel-inner {
    height: initial;
}

.carousel-indicators li {
    background-color: #2bc3c3
}

.quick-slide-open {
    box-shadow: 0px 0px 10px #2bc3c3;
    -webkit-animation: slide 0.5s forwards;
    animation: slide 0.5s forwards;
}


.quick-slide-close {
    -webkit-animation: slideclose 0.5s forwards;
    animation: slideclose 0.5s forwards;
}

.quick-slide-btn-open {
    -webkit-animation: slidebtn 0.5s forwards;
    animation: slidebtn 0.5s forwards;

}

.quick-slide-btn-close {
    -webkit-animation: slidebtnclose 0.5s forwards;
    animation: slidebtnclose 0.5s forwards;
}

.quick-slide-btn-close,
.quick-slide-btn-open,
.quick-slide-close,
.quick-slide-open {
    display: block !important;

}

.initial {
    animation: none !important;
    -webkit-animation: none !important;
    animation-duration: 0ms !important;
    -webkit-animation-duration: 0ms !important;
    display: none !important
}

#chapter-container.switch-open {
    left: 0%;
}

#collection-dialog .carousel-root,
#collection-dialog .slider-wrapper,
#collection-dialog .slider {
    max-height: 75vh;
}


/* --------------- NEW css from other css ---------- */
#calculator-results.modal {
    display: flex;
    flex-direction: column;
}

.modal-open #calculator-results.modal {
    display: flex !important;
    flex-direction: column;
    background-color: #000;
}

#calculator-results .modal-header {
    text-align: center;
    font-size: 50px;
    padding: 20px 15px;
    padding-right: 20px;
    background: #0f363a;
    text-shadow: 3px 5px 4px #000;
    border-bottom-color: #2bc3c3;
}
.toolbar {
    min-height: 40px;
    box-shadow: 0px 4px 15px #2bc3c3;
    border-top: 1px solid #2bc3c363;
}
.modal-header .close {
    margin-top: auto;
    margin-bottom: auto;
}

#calculator-results .modal-title {
    background: none !important;
    margin-top: 0;
}

#calculator-results .border {
    border-color: #2bc3c3 !important;
}

#calculator-results .close {
    color: #2bc3c3;
    text-shadow: 0 1px 0 #000;
    opacity: 1;
    box-shadow: none !important;
}

#calculator-results .modal-title {
    width: 100%;
}

@media(max-width: 501px) {
    #calculator-results .modal-title {
        font-size: 20px;
    }
}

#calculator-results .modal-content {
    background-color: #000;
    padding: 1rem .5rem;
    overflow: scroll;
}

#calculator-results .result-item {
    display: flex;
    flex-direction: row;
    text-align: center;
    width: 50%;
    padding: 0 5px;
    margin-top: 1rem;
    color: #2bc3c3;
}

@media(min-width: 767px) {
    #calculator-results .result-item {
        width: 33.333%;
    }
}

.result-border {
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
}

.result-item img {
    width: 100%;
}

#calculator-results .modal .result-item h5 {
    font-size: 1.1rem;
    color: #2bc3c3;

}

.result-item .fraction {
    min-width: 25px;
    justify-content: space-around;
    background-color: #0f363a;
    padding: 5px 0;
}

.calculator-credit {
    font-size: 10px;
}

@-webkit-keyframes slide {
    0% {
        left: min(-60%, -220px);
    }

    100% {
        left: 0%;
    }
}

@keyframes slide {
    0% {
        left: min(-60%, -220px);
    }

    100% {
        left: 0%;
    }
}

@keyframes slideclose {
    0% {
        left: 0%;
    }

    100% {
        left: min(-60%, -225px);
    }
}

@-webkit-keyframes slideclose {
    0% {
        left: 0%;
    }

    100% {
        left: min(-60%, -225px);
    }
}

@-webkit-keyframes slidebtn {
    0% {
        left: 0%;
    }

    100% {
        left: max(60%, 220px);
    }
}

@keyframes slidebtn {
    0% {
        left: 0%;
    }

    100% {
        left: max(60%, 220px);
    }
}

@keyframes slidebtnclose {
    0% {
        left: max(60%, 220px);
    }

    100% {
        left: 0%;
    }
}

@-webkit-keyframes slidebtnclose {
    0% {
        left: max(60%, 230px);
    }

    100% {
        left: 0%;
    }
}


@media(max-width: 767px) {
    #collection-dialog #rep-info .modal-title-container .ui-textinput {
        width: 75%;
    }
}

@media(max-width: 700px) {
    #list-grid-container.small-block-grid-2>li {
        width: 50%;
    }

    #list-grid-container.small-block-grid-2>li:nth-of-type(2n+1) {
        clear: both;
    }

    #rating-dialog {
        width: 80%;
        left: 10%;
        top: 20%;
    }
}

@media(min-width: 700px) {
    #list-grid-container.small-block-grid-2>li {
        width: 33.33%;
    }

    #list-grid-container.small-block-grid-2>li:nth-of-type(2n+1) {
        clear: none;
    }
}

@media(max-width: 500px) {
    #collection-dialog #morph-picture {
        max-height: 270px;
    }

}