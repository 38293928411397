* {
    font-family: 'Trebuchet MS', 'Arial Narrow', Arial, sans-serif;
}
html {
    overflow-x: hidden;
}
body {
    background-color: #000;
}
#footer-container {
    background-color:#333;
}
.navbar #logo {
    margin-bottom: initial;
    height: initial;
    width: 100%;
    overflow: hidden;
}
p {
    font-size: 1.5em;
}
.app-store-btn {
    border-radius: 2rem;
    padding: .5rem;
    margin: 1rem;
    box-shadow: 0px 0px 1rem #2bc3c3 !important;
}
.app-store-btn:hover {
    border-radius: 2rem;
    box-shadow: 0px 0px 2rem #2bc3c3 !important;
}
.product-device {
    position: relative;
    right: 10%;
    bottom: -18%;
    width: 400px;
    height: 640px;
    background-color: #333;
    border-radius: 21px;
    transform: rotate(21deg);
}
.product-device::before {
    position: absolute;
    top: 10%;
    right: 10px;
    bottom: 10%;
    left: 10px;
    content: "";
    background-color: rgba(255, 255, 255, .1);
    background-image: url('../../public/img/gene-calc-screen.png');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
}
@media screen and (max-width: 768px) {
    p {
        font-size: 1em;
    }
    .product-device {
        position:relative
    }
}


.navbar #logo img {
    margin-bottom: 0px;
    height: 55px;
    padding-right: 1rem;
    margin-top: 0;
}
.dropdown-toggle::after {
    margin-top: 13%;
}
.section {
    min-height: 100vh;
    min-width: 100vw;
    color: #fff;
}
.bg-img {
    background-size: contain;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

.navbar {
    background: #333;
    opacity: 1;
}

.navbar.scrolled {
    opacity: .7;
}

.navbar:hover {
    opacity: 1;
}
.navbar.scrolled {
    box-shadow: 0 0 15px #2bc3c3;
    transition: all .3s ease-in-out;
}


.navbar a {
    font-size: 1.5em;
    font-weight: 600;
    text-decoration: none;
}

body .section:nth-child(2) .d-flex.flex-row {
    flex-direction: row-reverse !important;
}

#peeker {
    position: relative;
    top: 26px;
    transform: rotate(4deg);
    content: url('../../public/img/peeker2.png');
}



#home, #demo {
    background: #000;
}

#home .bg-top {
    position: absolute;
    top: 0;
    right: 0;
}

#home {
    margin-top: 81px;
}

#home .bg-bottom {
    position: absolute;
    bottom: -96px;
    left: 0;
}

#home .bg-bottom img {
    width: 100%;
    height: 500px;
}

.section .content {
    position: relative;
    background: initial;
    z-index: 1;
}

#home.section .content {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 1rem;

}

#about, #browse-morphs {
    background: #086493;
    position: relative;
    z-index: 1;
}

#caresheet {
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.screen-image {
    width: 89%;
    height: 78.5%;
    transform: rotate(0deg);
    top: 9%;
    left: 4.8%;
    position: absolute;
}
.screenshot {
    background-size: contain;
    background-repeat: no-repeat;
}
.img-thumbnail {
    height: 300px;
    background-position: center;
}